import { useState } from 'react';
import Carousel from 'react-bootstrap/Carousel';
import main from '../Assets/main.png'
import img2 from '../Assets/carousel/ai2.png';
import img3 from '../Assets/carousel/ai3.png';
import img4 from '../Assets/carousel/ai4.png';
import img5 from '../Assets/carousel/ai5.png';
import img6 from '../Assets/carousel/ai6.png';
import img7 from '../Assets/carousel/ai7.png';

function Main() {
    const [index, setIndex] = useState(0);
    const handleSelect = (selectedIndex) => setIndex(selectedIndex);
    const carouselImages = [img2, img3, img4, img5, img6, img7];
    return (
        <>
            <div className="main-page-heading">
                <h1>Decision AI!</h1>
            </div>
            <div className="main-content">
                <div className="left-side">
                    <h2>How to get started</h2>
                    <ol>
                        <li>Sign up and use chat bot powered by open ai</li>
                        <li>Create board to pin conversations summarized as text or graphs</li>
                        <li>Connect your enterprise data via easy options</li>
                        <li>Decision AI will read the data and interpret it for you.</li>
                    </ol>
                </div>
                <div className="right-side">
                    <img src={main} alt="AI Illustration" />
                </div>
            </div>
            <div className='landing-page-cards'>
                <div className='page-card'>
                    <h2>Mission</h2>
                    <p>Bridge gap in AI Adoption</p>
                </div>
                <div className='page-card'>
                    <h2>Vision</h2>
                    <p>Enable professionals with AI to make decisions</p>
                </div>
            </div>
            <div id='who-we-are'>
                <Carousel
                    activeIndex={index}
                    onSelect={handleSelect}
                    interval={null}
                    controls={true}
                    indicators={false}
                >
                    {carouselImages.map((image, i) => (
                        <Carousel.Item key={i}>
                            <img className="d-block w-100" src={image} alt={`Slide ${i + 1}`} />
                        </Carousel.Item>
                    ))}
                </Carousel>
                <div className="carousel-pagination">
                    {carouselImages.map((_, i) => (
                        <button
                            key={i}
                            className={`pagination-button ${index === i ? 'active' : ''}`}
                            onClick={() => setIndex(i)}
                        >
                            {i + 1}
                        </button>
                    ))}
                </div>
                <div className='nav-links landing-page-buttons'>
                    <ul>
                        <li class="nav-item">
                            <a class="nav-link" aria-current="page" href="# ">Industries</a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link" aria-current="page" href="# ">Partnerships</a>
                        </li>
                    </ul>
                </div>
            </div>
        </>
    )
}

export default Main
