import { Container, Row, Col } from "react-bootstrap";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import React, { useRef, useState } from "react";
import emailjs from "@emailjs/browser";
import ReCAPTCHA from "react-google-recaptcha";

function Contact() {
    const [details, setDetails] = useState({
        user_name: "",
        user_email: "",
        user_message: ""
    });

    const [captchaValue, setCaptchaValue] = useState(null);
    const form = useRef();

    const handleChange = (event) => {
        setDetails(prev => ({ ...prev, [event.target.name]: [event.target.value] }))
    }

    const onCaptchaChange = (value) => {
        setCaptchaValue(value);
    };

    const sendData = (e) => {
        e.preventDefault();

        if (!captchaValue) {
            toast.warning("Please complete the CAPTCHA", {
                position: "top-center"
            });
            return;
        }

        emailjs.sendForm('service_xzlci6t', 'template_ivyfpj6', form.current, 'ePr8Ejju8OtdaDqmI')
            .then(setDetails({
                user_name: "",
                user_email: "",
                user_message: ""
            }))
            .catch(error => {
                toast.error("Failed to send message. Please try again.", {
                    position: "top-center"
                });
                console.error(error);
            });

        toast.success("Message Sent!", {
            position: "top-center"
        });
    };

    return (
        <section className="contact" id="connect">
            <ToastContainer />
            <Container>
                <Row className="align-items-center">
                    <Col size={24} md={12}>
                        <div className="animate__animated animate__fadeIn">
                            <h2>CONTACT US</h2>
                            <form ref={form} onSubmit={sendData}>
                                <Col>
                                    <Row size={12} sm={12} className="px-1">
                                        <input type="text" placeholder="Full Name" name="user_name" value={details.user_name} onChange={handleChange} autoComplete="off" required />
                                    </Row>
                                    <Row size={12} sm={12} className="px-1">
                                        <input type="email" placeholder="Email Address" name="user_email" value={details.user_email} onChange={handleChange} autoComplete="off" required />
                                    </Row>
                                    <Row size={12} className="px-1">
                                        <textarea rows="6" placeholder="Message" name="user_message" value={details.user_message} onChange={handleChange} autoComplete="off" required></textarea>
                                    </Row>
                                    <Row size={12} sm={12} className="px-1">
                                        <ReCAPTCHA
                                            sitekey="6Lez1i8qAAAAANbQoaHZQ-UsvDRJd8AunmhRC1oB"
                                            onChange={onCaptchaChange}
                                        />
                                    </Row>
                                    <Row size={12} className="px-1">
                                        <button type="submit"><span>Submit</span></button>
                                    </Row>
                                </Col>
                            </form>
                        </div>
                    </Col>
                </Row>
            </Container>
        </section>
    );
}

export default Contact;