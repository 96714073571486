import logo from '../Assets/logo.jpg'

const Navbar = () => {
    return (
        <>
            <div class="nav-bg">
                <nav class="navbar navbar-expand-lg">
                    <div class="container-fluid">
                        <img class="navbar-brand logo" src={logo} alt=""></img>
                        <h2 class="main-heading">CustomAI</h2>
                        <button class="navbar-toggler" type="button" data-bs-toggle="collapse"
                            data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent"
                            aria-expanded="false" aria-label="Toggle navigation">
                            <span class="navbar-toggler-icon"></span>
                        </button>
                        <div class="nav-links collapse navbar-collapse" id="navbarSupportedContent">
                            <ul class="navbar-nav ms-auto">
                                <li>
                                    <a class="nav-link contact-link" aria-current="page" href="#connect">Contact Us</a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </nav>
            </div>
        </>
    )
}

export default Navbar